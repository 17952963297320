import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Column from '../components/Column';

function DashboardPage() {
  const [board, setBoard] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBoard = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await fetch('http://localhost:3000/dashboard/531c0208-ebbc-4d98-9e62-4cac169f64e0', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setBoard(data);
      } catch (error) {
        console.error('Error fetching board:', error);
      }
    };

    fetchBoard();
  }, [navigate]);

  if (!board) {
    return <p>Loading...</p>;
  }

  return (
    <div className="p-6">
      <h1 className="text-3xl mb-4">{board.title}</h1>
      <div className="grid grid-cols-3 gap-4">
        {board.Columns.map(column => (
          <Column key={column.columnId} column={column} />
        ))}
      </div>
    </div>
  );
}

export default DashboardPage;
