import Deal from './Deal';

function Column({ column }) {
  return (
    <div className="bg-gray-100 p-4 rounded shadow">
      <h2 className="text-xl mb-4">{column.title}</h2>
      {column.Deals.map(deal => (
        <Deal key={deal.dealId} deal={deal} />
      ))}
    </div>
  );
}

export default Column;
