import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Navbar() {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <nav className="bg-blue-500 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div>
          <Link to="/" className="text-xl font-bold">LexFlow</Link>
        </div>
        <div>
          <Link to="/dashboard" className="mr-4">Dashboard</Link>
          <Link to="/companies" className="mr-4">Companies</Link>
          {token ? (
            <button onClick={handleLogout} className="bg-red-500 px-3 py-2 rounded hover:bg-red-600">
              Logout
            </button>
          ) : (
            <Link to="/login" className="bg-green-500 px-3 py-2 rounded hover:bg-green-600">
              Login
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
