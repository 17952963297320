import React, { useEffect, useState } from 'react';
import RegisterCompanyModal from '../components/RegisterCompanyModal';

function CompaniesPage() {
  const [companies, setCompanies] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch('http://localhost:3000/company', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = await response.json();
        setCompanies(data);
      } catch (error) {
        console.error('Erro ao buscar companies:', error);
      }
    };

    fetchCompanies();
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCompanyRegistered = (newCompany) => {
    setCompanies([...companies, newCompany]);
  };

  return (
    <div className="p-6">
      <h1 className="text-3xl mb-4">Companies</h1>
      <button
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        onClick={handleOpenModal}
      >
        Register New Company
      </button>

      {companies.length > 0 ? (
        <ul className="mt-6">
          {companies.map((company) => (
            <li key={company.companyId} className="p-4 mb-4 bg-gray-100 rounded shadow">
              <h3 className="text-xl font-semibold">{company.name}</h3>
              <p>Industry: {company.industry}</p>
              {company.website && <a href={company.website} target="_blank" rel="noopener noreferrer">{company.website}</a>}
            </li>
          ))}
        </ul>
      ) : (
        <p>No companies found.</p>
      )}

      {isModalOpen && (
        <RegisterCompanyModal onClose={handleCloseModal} onCompanyRegistered={handleCompanyRegistered} />
      )}
    </div>
  );
}

export default CompaniesPage;
