import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function HomePage() {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (token) {
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  }, [token, navigate]);

  return (
    <div className="flex justify-center items-center h-screen">
      <h1 className="text-2xl">Redirecting...</h1>
    </div>
  );
}

export default HomePage;
