function Deal({ deal }) {
    return (
      <div className="bg-white p-3 mb-4 rounded shadow">
        <h3 className="text-lg font-semibold">{deal.title}</h3>
        <p>Industry: {deal.industry}</p>
        <p>Value: ${deal.totalValue}</p>
        <p>Priority: {deal.priority}</p>
      </div>
    );
  }
  
  export default Deal;
  